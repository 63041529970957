import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect, Switch} from "react-router-dom";
import AdminButton from "./AdminButton";
import Event from "../Log/Event/Event";
import Header from "./Header";
import Home from "../Home/Home";
import InfoPage from "../Information/InfoPage";
import Log from "../Log/Log";
import Logo from "./Logo";
import Main from "./Main";
import Menu from "./Menu";
import Organization from "../Organizations/Organization/Organization";
import Organizations from "../Organizations/Organizations";
import User from "../Users/User/User";
import Users from "../Users/Users";
import {
    container,
    errorMessage
} from "./Layout.css";

class Layout extends PureComponent {

    render () {

        if (window.innerWidth < 1158) {
            return (
                <div className={errorMessage}>
                    <h2>Dette er en kontorløsning og det er dermed ikke støtte for små skjermer.</h2>
                    <h5>Prøv å åpne siden i et større vindu.</h5>
                </div>
            )
        }

        if (!this.props.profile) {
            return null;
        }

        return (
            <div className={container}>
                <Logo />
                <Header />
                <AdminButton
                    username={this.props.profile.name ? this.props.profile.name : ''}
                />
                <Menu/>
                <Main>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/brukere" component={Users} exact />
                        <Route path={"/brukere/:uuid"} component={User} exact />
                        <Route path="/bedrifter" component={Organizations} exact />
                        <Route path="/bedrifter/:uuid" component={Organization} exact />
                        <Route path={"/logg"} component={Log} exact />
                        <Route path={"/logg/:uuid"} component={Event} exact />
                        <Route path={"/informasjon"} component={InfoPage} exact />
                        <Redirect to={ "/404" } />
                    </Switch>
                </Main>
            </div>
        )
    }
}

Layout.propTypes = {
    profile: PropTypes.shape({
        name: PropTypes.string.isRequired
    })
};

const mapStateToProps = ({ admin }) => ({
    profile: admin.profile
});

export default connect(
    mapStateToProps,
    null
)(Layout);
