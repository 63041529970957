/* eslint-disable no-undef */
import {setError, setInformation} from "./errors";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const setUsersIsLoading = (loading) => ({
    type: "USERS_SET_LOADING",
    loading
});

const setUsers = (users) => ({
    type: "USERS_SET_USERS",
    users
});

export const getUsers = (query) => (dispatch) => new Promise(() => {
    dispatch(setUsersIsLoading(true));
    fetch(`${API_URL}/account/user?query=${query}&status=`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            if (data.length === 0) {
                dispatch(setInformation("Ingen resultater", "Gjør et nytt søk"))
            }
            dispatch(setUsers(data));
            dispatch(setUsersIsLoading(false));
            return data;
        })
        .catch(error => {
            dispatch(setError("Det har skjedd en feil", "Prøv å last siden på nytt"));
            dispatch(setUsersIsLoading(false));
            return error;
        })
});