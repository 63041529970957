/* eslint-disable no-undef */
import {setUser} from "./admin";
import {setAnnouncement} from "./errors";
import {getAdvancedPermissions, getSimplePermissions} from "./permissions";
import {getServices} from "./services";
import {getEvents} from "./events";
import moment from "moment";
import { push } from "react-router-redux";
import { showSpinner } from "utils/loading.js";

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

// This method is only called once at first rendering.
// Gets messages about maintenance of the page.
export const loadAnnouncement = () => (dispatch) => {
    fetch(`${API_URL}/service/announcements/current`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setAnnouncement('OBS: ' + data.message, `${moment(data.validFrom).format("DD.MM.YYYY")} - ${moment(data.validTo).format("DD.MM.YYYY")}`));
            return data;
        })
        .catch(error => {
            return error;
        })
};

// This method is only called once at first rendering.
// Handles redirecting for login.
export const loadProfile = () => (dispatch) => (
    fetch(`${API_URL}/account/me`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            dispatch(setUser(data));
            dispatch(getAdvancedPermissions());
            dispatch(getSimplePermissions());
            dispatch(getServices());
            dispatch(getEvents());
            showSpinner(false);
            return data;
        })
        .catch(error => {
            dispatch(push('/inngang'));
            showSpinner(false);
            return error;
        })
);
